import { Notifier } from 'Core/Common/Notifier';
import * as ko from 'knockout'

import 'lockr';

import { BaseControl } from 'Core/Controls/BaseControl/BaseControl'
import { EVENTS } from 'Core/Constant'
import { IControlParam, IForm } from 'Core/Screens/IScreen'
import { IControl } from 'Core/Controls/IControl'
import {
	ConfirmationDialog,
	Types as DialogTypes,
	EVENTS as DIALOG_EVENTS
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import ViewTemplate from 'Core/Controls/Step/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/Step/Templates/HelpView.html'
import DesignTemplate from 'Core/Controls/Step/Templates/Design.html'
import ToolBarTemplate from 'Core/Controls/Step/Templates/ToolBar.html'
import { StepPage } from '../StepPage/StepPage';
import { RecordLinker } from '../../Components/RecordLinker/RecordLinker';
import { StepApi } from './Stores/StepApi';
import { TransactionApi } from './Stores/TransactionApi';
import { Guid } from '../../Common/Guid';
import { LOCK_EVENTS, LockManager } from "Core/Components/Locker/LockManager";

ko.templates['Core/Controls/Step/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Step/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Step/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Step/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/Step/Templates/Design'] = DesignTemplate;

export class Step extends BaseControl {
	private _currentStepPage: KnockoutObservable<StepPage> = ko.observable(null);
	private _currentStepIndex: KnockoutObservable<number>;
	private _isEnablePreviousBtn: KnockoutComputed<boolean>;
	private _isEnableNextBtn: KnockoutComputed<boolean>;
	private _transactionId: string;
	private _pulseTimer: number;
	private _subjectRecordId: number;

	constructor(params: IControlParam) {
		super(params);
		this._currentStepIndex = ko.observable(0);

		this._isEnablePreviousBtn = ko.computed(() => {
			return this._currentStepPage() && this._currentStepIndex() != 0;
		});

		this._isEnableNextBtn = ko.computed(() => {
			return !!this._currentStepPage();
		});

		this.Init();
		this.AddEvent('FINISH_PROCESS');
	}

	ApplyProperties() { }

	get Tabs() {
		return this._subControls;
	}

	GetForm(): IForm {
		return this._form;
	}

	private Init(): void {
		this.On('AFTER_RENDER', this, () => {

			if (this.IsRunTime && !this.Screen.IsInModal()) {
				this._transactionId = Guid.NewGuid();
				TransactionApi.Start({ Id: this._transactionId }).then(() => {
					this.RestartPulseTransaction();
					this._currentStepPage()?.LoadScreen(this._transactionId);
				});
			}

			ko.utils.domNodeDisposal.addDisposeCallback(this._el, () => this.Dispose())
		});

		this.SetActiveTab(0);

		this._subControls().forEach((page: StepPage, index) => {
			page.Index = index + 1;
			page.Wait = !page.Process;
			page.On(EVENTS.ON_TAB_CLICK, this, (eventArgs: any) => {
				if (this._currentStepPage() == page) {
					return;
				}

				const tabPage = <StepPage>eventArgs.source;
				this.SetActiveTabPage(tabPage);
			});
		});
	}

	SetActiveTabPage(tabPage: StepPage) {
		const currentPage = this._currentStepPage();

		if (currentPage) {

			if (tabPage) {
				const canSwitchToAdjacentTab = Math.abs(currentPage.Index - tabPage.Index) === 1;
				if (!canSwitchToAdjacentTab && this.IsRunTime) {
					Notifier.Warning('Can switch only to adjacent Step');
					return;
				}
			}

			if (this.IsRunTime) {
				currentPage.Save().then(() => {
					currentPage.Finish = true;
					currentPage.Process = false;
					if (currentPage.IsNewRecord) {
						this.CreateLink();
					}
					currentPage.SetIsActive(false);

					if (tabPage) {

						tabPage.LoadRecord({ entityId: currentPage.EntityId, recordId: currentPage.StepRecordId, subjectRecordId: this._subjectRecordId, transactionId: this._transactionId });

						tabPage.SetIsActive(true);
						tabPage.Finish = false;
						tabPage.Wait = false;
						tabPage.Process = true;

						this._currentStepIndex(this._subControls().indexOf(tabPage));
						this._currentStepPage(tabPage);
					} else {
						TransactionApi.Commit({ Id: this._transactionId }).then(() => {
							this.ReleaseLock();
							this.Trigger('FINISH_PROCESS');
							clearInterval(this._pulseTimer);
							this._currentStepPage(null);
							this._transactionId = null;
						});
					}
				});
			}

			if (this.IsDesignTime) {
				currentPage.SetIsActive(false);
				tabPage.SetIsActive(true);
				this._currentStepIndex(this._subControls().indexOf(tabPage));
				this._currentStepPage(tabPage);
			}
		}
	}

	ReleaseLock() {
		_.each(this._subControls(), (subControl) => {
			let stepPage = subControl as StepPage;
			LockManager.Instance.ReleaseLock(stepPage.StepEntityId, stepPage.StepRecordId);
		});
	}

	CreateLink() {
		let previousPage: StepPage = this._subControls()[this._subControls().indexOf(this._currentStepPage()) - 1] as StepPage;
		const relatedEntityName = this._currentStepPage().EntityName;
		if (previousPage) {
			const mainEntityName = previousPage.EntityName;			
			RecordLinker.LinkRecord({ TransactionId: this._transactionId, MainTableId: previousPage.EntityId, MainRecordId: previousPage.StepRecordId, LinkedTableId: this._currentStepPage().EntityId, LinkedRecordId: this._currentStepPage().StepRecordId })
				.then((result) => {
					if (!result.IsSuccessfull) {
						Notifier.Failed(`Could not proceed, please, create the link between ${mainEntityName} and ${relatedEntityName}`);
					}
				});

			if(previousPage.EntityId != this.Screen.GetEntityId() || (previousPage.EntityId == this.Screen.GetEntityId() && previousPage.StepRecordId != this._subjectRecordId)){
				RecordLinker.LinkRecord({ TransactionId: this._transactionId, MainTableId: this.Screen.GetEntityId(), MainRecordId: this._subjectRecordId, LinkedTableId: this._currentStepPage().EntityId, LinkedRecordId: this._currentStepPage().StepRecordId })
				.then((result) => {
					if (!result.IsSuccessfull) {
						Notifier.Failed(`Could not proceed, please, create the link between ${mainEntityName} and ${relatedEntityName}`);
					}
				});
			}
		}else{			
			RecordLinker.LinkRecord({ TransactionId: this._transactionId, MainTableId: this.Screen.GetEntityId(), MainRecordId: this._subjectRecordId, LinkedTableId: this._currentStepPage().EntityId, LinkedRecordId: this._currentStepPage().StepRecordId })
			.then((result) => {
				if (!result.IsSuccessfull) {
					Notifier.Failed(`Could not proceed, please, create the link between ${this.Screen.GetEntityName()} and ${relatedEntityName}`);
				}
			});
		}
	}

	SetActiveTab(tabIndex: number) {
		const subControls = this._subControls();
		if (subControls[tabIndex]) {
			const tab = <StepPage>subControls[tabIndex];
			if (this._currentStepPage()) {
				this._currentStepPage().SetIsActive(false);
			}
			tab.SetIsActive(true);
			tab.Finish = false;
			tab.Wait = false;
			tab.Process = true;
			this._currentStepPage(tab);
			this._currentStepIndex(tabIndex);

		}
	}

	SetValue(value: any): void {
	}

	AddSubControl(control: IControl) {
		var stepPage = control as StepPage;
		this._subControls.push(control);

		if (this._currentStepPage()) {
			this._currentStepPage().SetIsActive(false);
		}
		stepPage.SetIsActive(true);
		this._currentStepPage(stepPage);

		stepPage.On(EVENTS.ON_TAB_CLICK, this, () => {
			if (this._currentStepPage()) {
				this._currentStepPage().SetIsActive(false);
			}
			stepPage.SetIsActive(true);
			this._currentStepPage(stepPage);
		});
	}


	RemoveControl(control: IControl) {
		var wrappers = new Array<Element>(control.GetWrapper());

		_.each(this._currentStepPage().GetAllSubControls(),
			(subControl) => {
				wrappers.push(subControl.GetWrapper());
			});

		wrappers.map((el) => {
			$(control.GetWrapper()).add(el).fadeOut('slow', () => {
				var subControls = this._subControls();
				var tabPage = control as StepPage;
				this._subControls.remove(control);

				if (tabPage.GetIsActive()) {
					if (this._currentStepIndex() <= subControls.length - 1) {
						this.SetActiveTab(this._currentStepIndex());
					} else if (this._currentStepIndex() > 0) {
						this.SetActiveTab(this._currentStepIndex() - 1);
					} else {
						this._currentStepPage(null);
					}
				} else {
					this.SetActiveTabPage(this._currentStepPage());
				}

				(this.GetForm().GetScreen() as any).OnControlRemoved(control);
			});
		});
	}

	Previous() {
		const tabPage = this._subControls()[this._subControls().indexOf(this._currentStepPage()) - 1] as StepPage;
		this.SetActiveTabPage(tabPage);
	}

	Next() {
		const tabPage = this._subControls()[this._subControls().indexOf(this._currentStepPage()) + 1] as StepPage;
		this.SetActiveTabPage(tabPage);
	}

	get CurrentTabPage(): StepPage {
		return this._currentStepPage();
	}

	SelectRecord({ recordId }: { recordId: number }) {
		this.SetActiveTab(0);

		if (this._transactionId) {
			TransactionApi.Rollback({ Id: this._transactionId }).then(() => {
				this._transactionId = Guid.NewGuid();

				TransactionApi.Start({ Id: this._transactionId }).then(() => {
					this.RestartPulseTransaction();
					this._currentStepPage().SelectRecord({ recordId, transactionId: this._transactionId });
				});
			});

		} else {
			this._transactionId = Guid.NewGuid();

			TransactionApi.Start({ Id: this._transactionId }).then(() => {
				this.RestartPulseTransaction();
				this._currentStepPage().SelectRecord({ recordId, transactionId: this._transactionId });
			});
		}
	}

	SelectRecord2({ recordId }: { recordId: number }) {
		this.SetActiveTab(0);
		this._subjectRecordId = recordId;
		this._transactionId = Guid.NewGuid();
		TransactionApi.Start({ Id: this._transactionId }).then(() => {
			this.RestartPulseTransaction();
			this._currentStepPage()?.LoadRecord({ entityId: this.Screen.GetEntityId(), recordId, subjectRecordId: this._subjectRecordId, transactionId: this._transactionId });
		});
	}

	Dispose(): void {
		if (this._transactionId) {
			clearInterval(this._pulseTimer);
			TransactionApi.Rollback({ Id: this._transactionId });
			this.ReleaseLock();
		}
	}

	RestartPulseTransaction() {
		clearInterval(this._pulseTimer);
		this._pulseTimer = setInterval(() => {
			TransactionApi.Continue({ Id: this._transactionId });
		}, 10000);
	}
} 