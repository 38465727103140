import {BaseControlEditor} from 'Core/Screens/DesignScreen/ControlEditor/Editors/BaseControlEditor';
import {IControl} from 'Core/Controls/IControl';
import {Behavior} from 'Core/Controls/ButtonCopy/Constants/Behavior';

export class ButtonCopyControlEditor extends BaseControlEditor {
    constructor(control: IControl) {
        super(control);
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);

        this.CheckProperties();
    }

    private CheckProperties() {
        let behavior = this.GeneralProperties.GetPropertyManager('Behavior');
        let type = this.GeneralProperties.GetPropertyManager('Type');
        let aliasSuffix = this.GeneralProperties.GetPropertyManager('AliasSuffix');

        if (behavior && type && aliasSuffix) {
            let isEnabled = behavior.Value() && behavior.Value().Value === Behavior.CopyToType;

            type.SetEnabled(isEnabled);
            aliasSuffix.SetEnabled(isEnabled);

            if (!isEnabled){
                type.SetValue(null);
                aliasSuffix.Value('');
            }

            behavior.Value.subscribe((val)=>{
                isEnabled = val && val.Value === Behavior.CopyToType;

                type.SetEnabled(isEnabled);
                aliasSuffix.SetEnabled(isEnabled);

                if (!isEnabled){
                    type.SetValue(null);
                    aliasSuffix.Value('');
                }
            });
        }
    }
}