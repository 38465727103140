///<reference path="../libs/typings/knockout.d.ts" />
///<reference path="../typings/metronic.d.ts" />
///<reference path="../typings/metronicLayout.d.ts" />
/////<reference path="../libs/typings/backbone.d.ts" />
/////<reference path="../libs/typings/jointjs/joint.d.ts" />
/////<reference path="../libs/typings/jointjs/joint.head.d.ts" />
/////<reference path="../libs/typings/jointjs/geometry.d.ts" />
/////<reference path="../libs/typings/jointjs/vectorizer.d.ts" />

import * as ko from 'knockout'
import * as _ from 'underscore';
import * as stringTemplateEngine from 'stringTemplateEngine'
import * as $ from 'jquery'
import 'jqueryBlockui'

import {BaseScreen} from 'Core/Screens/BaseScreen'
import {Search} from 'Core/Controls/Search/Search'
import {LookupEditor} from 'LookupEditor/LookupEditor'
import {FieldCollection} from "FieldCollection/FieldCollection";
import {MenuAreas} from 'MenuManager/MenuAreas/MenuAreas'
import {KnockoutExtenions} from 'Core/KnockoutExtentions/Extentions'
import {DesignedScreenModel} from 'MenuManager/MenuAreas/Models/DesignedScreenModel'
import {MenuAreaModel} from 'MenuManager/MenuAreas/Models/MenuAreaModel'
import {ScreenManager} from 'Core/ScreenManager/ScreenManager'
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen'
import {MobileChecker} from 'Core/Common/MobileChecker'
import {Notifier} from 'Core/Common/Notifier'
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen'
import {IScreen, IScreenState} from 'Core/Screens/IScreen'
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes'
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager'
import {FormDesignerPage} from 'Pages/FormDesignerPage'
import {CanvasDesignerPage} from 'Pages/CanvasDesignerPage'
import {FunctionDesignerPage} from 'Pages/FunctionDesignerPage'

import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen'
import {Guid} from 'Core/Common/Guid'
import {BlockUI} from 'Core/Common/BlockUi'
import {CookieManager} from "Core/Common/CookieManager";
import {CyberConfigManager} from 'Core/CyberConfigManager/CyberConfigManager';
import {LOCK_EVENTS, LockManager} from "Core/Components/Locker/LockManager";
import {UserDataRoles, UserManager, UserRoles, UserTypes} from 'User/UserManager';
import {PathRunner} from "Core/Components/PathRunner/PathRunner";
import {CONTROL_TYPES, LIFE_STATUS_GROUPS, NotTypedScreenTypes} from "Core/Constant";
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import {
    BREADCRUMBS_HYPERLINK_TOPIC,
    BREADCRUMBS_NEW_VALUE_TOPIC,
    BREADCRUMBS_FIELD_RECORD
} from 'MenuManager/Breadcrumbs/Breadcrumbs';
import {LockScreen} from "LockScreen/LockScreen";
import {HelpBook} from 'HelpBook/HelpBook';
import {P} from 'Core/Common/Promise';
import {UserMenu} from "MenuManager/UserMenu/UserMenu";
import {GridRow} from 'Core/Controls/Grid/BaseGrid/GridRow/GridRow';
import {EVENTS as EDIT_SCREEN_EVENTS} from 'Core/Screens/EditScreen/Events';
import {ZIndexManager} from 'Core/Common/ZIndexManager';

import 'pubsub';
import {DataModes} from "Core/Enums/DataModes";
import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {TranslationEditorPage} from '../Pages/TransaltionEditorPage/TranslationEditorPage';
import {PortletsPage} from "../Core/Portlets/PortletsPage";
import {Tab} from "../Core/Controls/Tab/Tab";
import {TabPage} from "../Core/Controls/TabPage/TabPage";
import {Agenda} from "../Core/Controls/Agenda/Agenda";
import {RecordStore} from "../Core/Common/Stores/RecordStore";
import {ConsultScreen} from "Core/Screens/ConsultScreen/ConsultScreen";
import {GuestHomePage} from "../GuestHomePage/GuestHomePage";
import {GlobalManager, GLOBALS} from "../Core/GlobalManager/GlobalManager";
import {TableStore} from "../Core/Common/Stores/TableStore";
import {RecordSecurityStore} from '../Core/Common/Stores/RecordSecurityStore';
import {FollowupModes} from 'Core/Constants/FollowupModes';
import {RECORD_SECURITY_WORDS} from 'Core/Constants/RecordSecurityWords';
import {NetworkDesigner} from '../Core/NetworkDesigner/NetworkDesigner';
import { BoldBiDesignerPage } from 'Pages/BI/BIDesignerPage';

import { AdministrationPage } from '../Pages/AdministrationPage/AdministrationPage';
import {IControl} from "Core/Controls/IControl";
import {Timer} from "Core/Controls/Timer/Timer";
import { DictionaryComparisonPage } from 'Pages/DictionaryComparison/DictionaryComparisonPage';
import { productFruits } from 'product-fruits';

//Templates
import MenuManagerTemplate from 'MenuManager/Templates/MenuManager.html';
import {FieldDataModel} from "../Core/Screens/Models/RecordDataModel";

ko.templates['MenuManager/Templates/MenuManager'] = MenuManagerTemplate;

enum NavigationTypes {
    DesignScreen,
    Portlets,
    LookupEditor,
    DatabaseDesigner,
    FormDesigner,
    CdToolkit,
    CanvasDesigner,
	FunctionDesigner,
	FieldCollectionDesigner,
    TranslationEditor,
    CyberConfig,
    NetworkDesigner,
    BIDesigner,
    Administration
}

interface IHistoryState {
    Guid: string;
    UserId: number;
    SecurityRolesKey: number;
    DbId: number;
    ScreenId: number;
    EntityId: number;
    RecordId: number;
    NavigationType: NavigationTypes;
}

class CustomStyleProperties {
    [key: string]: string | null;
}

export class MenuManager {
    private static instance;
    private static allowInstantiation: boolean;
    private _pageBodyContainerId = 'viewBody';
    private _guestHomePage: GuestHomePage;
    private _menuAreas: MenuAreas;
    private _selectedScreen: DesignedScreenModel;
    private _selectedArea: MenuAreaModel;
    private _history: Historyjs = <any>History;
    private _popState: boolean;
    private _screenStates: Array<IScreenState>;
    private _currentScreen: IScreen;
    private _currentSate: IHistoryState;
    private _openedScreens: BaseScreen[];
    private _isDesignersAreaActive: KnockoutObservable<boolean>;
    private _activeDesignerMenuItem: KnockoutObservable<string>;
    IsAuthenticated: KnockoutObservable<boolean>;


    private _enableSystemMenu: KnockoutComputed<boolean>;
    private _enableDesignerMenu: KnockoutObservable<boolean>;
    private _enableLookupEditorMenu: KnockoutObservable<boolean>;
    private _enableCdToolkitMenu: KnockoutObservable<boolean>;
    private _enableDatabaseDesignerMenu: KnockoutObservable<boolean>;
    private _enableFieldCollectionDesignerMenu: KnockoutObservable<boolean>;
    private _enableFormDesignerMenu: KnockoutObservable<boolean>;
    private _enableCanvasDesignerMenu: KnockoutObservable<boolean>;
    private _enableBIDesignerMenu: KnockoutObservable<boolean>;
    private _enableNetworkDesignerMenu: KnockoutObservable<boolean>;
    private _enableFunctionDesignerMenu: KnockoutObservable<boolean>;
    private _enableCyberConfigMenu: KnockoutObservable<boolean>;
    private _enableDictionaryComparisonMenu: KnockoutObservable<boolean>;
    private _enableTranslationEditorMenu: KnockoutObservable<boolean>;
    private _enableAdministrationMenu: KnockoutObservable<boolean>;
    private _labels = LABELS;
    protected _help: HelpBook;
    private _pathRunnerRefreshed: KnockoutObservable<boolean>;
    private _isRecordRetired: boolean;
    private _isProductfruitsInitalized: boolean;

    CashedScreen: IScreen;

    constructor(isAuthenticated: boolean = false) {

        if (!MenuManager.allowInstantiation) {
            throw new Error('Use MenuManager.Instance instead');
        }
        this.InitKnockout();

        this._menuAreas = new MenuAreas();
        this._openedScreens = [];
        this.CashedScreen = null;
        this._isDesignersAreaActive = ko.observable(false);
        this._activeDesignerMenuItem = ko.observable(null);
        this._screenStates = [];
        this.Init();
        this.IsAuthenticated = ko.observable(isAuthenticated);
        this.IsAuthenticated.subscribe(authenticated => this._menuAreas.ShowHideSearchButtons());

        this._enableDesignerMenu = ko.observable(false);
        this._enableLookupEditorMenu = ko.observable(false);
        this._enableCdToolkitMenu = ko.observable(false);
        this._enableDatabaseDesignerMenu = ko.observable(false);
        this._enableFieldCollectionDesignerMenu = ko.observable(false);
        this._enableFormDesignerMenu = ko.observable(false);
        this._enableCanvasDesignerMenu = ko.observable(false);
        this._enableNetworkDesignerMenu = ko.observable(false);
        this._enableFunctionDesignerMenu = ko.observable(false);
        this._enableCyberConfigMenu = ko.observable(false);
        this._enableTranslationEditorMenu = ko.observable(false);
        this._enableBIDesignerMenu = ko.observable(false);
        this._enableAdministrationMenu = ko.observable(false);
        this._pathRunnerRefreshed = ko.observable(false);
        this._enableDictionaryComparisonMenu = ko.observable(false);
        this._isRecordRetired = false;
        this._isProductfruitsInitalized = false;

        this._enableSystemMenu = ko.computed(() => this._enableCyberConfigMenu());

        this._help = HelpBook.Instance;

        PubSub.subscribe(PUB_SUB_EVENTS.REFRESH_MAIN_MENU, () => {
            this.RefreshMenuAreas();
        });

        PubSub.subscribe(PUB_SUB_EVENTS.GO_TO_SCREEN_BY_TYPE, (message, data) => {
            this.GoToScreenByType(data.EntityId, data.ScreenType, data.IsOpenInModal);
        });

        PubSub.subscribe(PUB_SUB_EVENTS.GO_TO_CONSULT_SCREEN, (message, data) => {
            this.GoToConsultScreen(data.EntityId, data.RecordId, data.TypeId || data.RecordTypeId, data.IsOpenInModal)
                .then((screen: BaseScreen) => {
                    if (screen.GetType() === ScreenTypes[ScreenTypes.ConsultScreen]) {
                        PathRunner.Instance.GetUnit(data.EntityId, screen.IsSpecialScreenExist).LoadNewData(data.RecordId);
                    }
                });
        });

        PubSub.subscribe(PUB_SUB_EVENTS.GO_TO_EDIT_SCREEN, (message, data) => {
            const deferred = P.defer<IScreen>();

            this.GoToEditScreen(data.EntityId, data.RecordId, data.TypeId || data.RecordTypeId, data.IsOpenInModal, data.Owner)
                .then(screen => deferred.resolve(screen));
        });

        PubSub.subscribe(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, (message, data) => {
            this.GoToRecordScreen(
                data.EntityId,
                data.RecordId,
                data.TypeId || data.RecordTypeId,
                data.IsOpenInModal,
                data.Owner,
                data.CurrentRow,
                data.RowList,
                false, // data.IsOpenAgenda,
                data.IsHyperlink
            )
                .then((screen: BaseScreen) => {
                    if (data.IsHyperlink && data.ViewValue && !data.IsOpenInModal) {
                        PubSub.publish(BREADCRUMBS_HYPERLINK_TOPIC, {
                            Screen: screen,
                            Name: data.ViewValue
                        });
                    }

                    if (screen.GetType() === ScreenTypes[ScreenTypes.ConsultScreen]) {
                        PathRunner.Instance.GetUnit(data.EntityId, screen.IsSpecialScreenExist).LoadNewData(data.RecordId);
                    }
                });
        });

        PubSub.subscribe(PUB_SUB_EVENTS.RELOAD_SCREEN, () => {
            if (this.CashedScreen) {
                this.CashedScreen.Refresh();
            }
        });

        PubSub.subscribe(PUB_SUB_EVENTS.SCREEN_OPENED, (message, screen: BaseScreen) => {
            const applicableTypes = [ScreenTypes.ConsultScreen, ScreenTypes.EditScreen];
            const typeIsApplicable = _.any(applicableTypes, type => ScreenTypes[type] === screen.GetType());

            if (!screen.IsInModal()) {
                this._openedScreens = [];
            }

            if (!typeIsApplicable) {
                return;
            }

            if (this._pathRunnerRefreshed()) {
                PathRunner.Instance.GetUnit(screen.GetEntityId(), screen.IsSpecialScreenExist).LoadNewData(screen.GetRecordId());
                this._pathRunnerRefreshed(false);
            }

            if (this._isRecordRetired) {
                PathRunner.Instance.GetUnit(screen.GetEntityId(), screen.IsSpecialScreenExist).LoadNewData(screen.GetRecordId());
                this._isRecordRetired = false;
            }

            this._openedScreens.push(screen);
        });

        PubSub.subscribe(PUB_SUB_EVENTS.SCREEN_CLOSED, (message, screen: BaseScreen) => {
            this.HandleScreenClosedEvent(screen);
        });

        PubSub.subscribe(PUB_SUB_EVENTS.CHANGE_SCREEN, (message, screen: BaseScreen) => {
            this._currentScreen = screen;
            this.ChangeTableTypeFlowFolder();
        });

        PubSub.subscribe(PUB_SUB_EVENTS.CLOSE_POPUPS, () => {
            this.ClosePopupScreens();
        });

        PubSub.subscribe('SESSION_LOCKED', (message, data) => {
            new LockScreen().ShowInModal();
        });

        PubSub.subscribe('PATH_RUNNER_NAVIGATE', (message, data) => {
            UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.RecordTypeId);
            let lastOpenedScreen = this._openedScreens[this._openedScreens.length - 1];
            this._currentScreen = lastOpenedScreen;
            var inModal = this._currentScreen ? this._currentScreen.IsInModal() : false;
            this.GoToConsultScreen(data.EntityId, data.RecordId, data.RecordTypeId, inModal).then((screen) => {
                if (this._currentScreen) {
                    this.ProhibitionAutoStopTimerForCurrentScreen(this._currentScreen, screen);
                    this._currentScreen.Close(true);
                    this.HandleScreenClosedEvent(lastOpenedScreen);
                }
                this._currentScreen = screen;

                if (data.LifeStatusName === "Retired") {
                    this._isRecordRetired = true;
                }

                this.ChangeTableTypeFlowFolder();
            });
        });

        PubSub.subscribe('RELOAD_MENU_AREAS', (message, data) => {
            this._menuAreas.Refresh(false);
        });


        PubSub.subscribe('GO_TO_FORM_DESIGNER', () => {
            LockManager.Instance.ReleaseAllLocks();
            this.ShowFormDesigner();
        });

        PubSub.subscribe('GO_TO_CANVAS_DESIGNER', () => {
            LockManager.Instance.ReleaseAllLocks();
            this.ShowCanvasDesigner();
        });

        this._isDesignersAreaActive.subscribe(newValue => {
            if (!newValue) {
                this._activeDesignerMenuItem(null);
            }
        });

        PubSub.subscribe('SCREEN_NOT_IMPLEMENTED', () => {
            this.PushState(null, NavigationTypes.Portlets);
            this.ShowPortlets();
        });
    }

    ChangeTableTypeFlowFolder() {
        const baseScreen = this._currentScreen as BaseScreen;
        if (baseScreen){
            this._menuAreas.OpenedTypeFlowFolder = baseScreen.GetTableTypeFlowFolder();
        }
    }

    GetCurrentScreen() {
        return this._currentScreen;
    }

    GetTopScreen() {
        return _.last(this._openedScreens) || null;
    }

    ClosePopupScreens() {
        const modalScreens = this._openedScreens.filter(screen => screen.IsInModal());
        modalScreens.forEach(screen => screen.Close());
    }

    static SetNewTitle(title: string) { //dynamic breadcrumb Title
        let licenseName = UserManager.Instance.CurrentUser.LicenseName;
        document.title = title + ' - ' + licenseName;
    }

    GetTemplateName(): string {
        return 'MenuManager/Templates/MenuManager';
    }

    AfterRender() {
    }

    DeleteAreas() {
        this._menuAreas.DeleteAreas();
        $(`#${this._pageBodyContainerId}`).html('');
    }

    Init() {
        this._menuAreas.On('UPDATED', this, (eventArgs: any) => {
            this.RemoveOverlay();
            if (eventArgs.data.ReloadScreen) {
                this.RestoreMenuState();
            }
            this.MobileVersion();
            this.InitProductFruits();
        });

        var self = this;
        this._history.Adapter.bind(window, 'statechange', () => {
            if (!self._popState) {
                self.RestoreMenuState();
            }
            self._popState = false;
        });
    }

    IsHomeScreenType(homeScreen: string) {
        let splitHomeScreen = homeScreen.split('.');
        let screenType = splitHomeScreen[1];
        return screenType === ScreenTypes[ScreenTypes.ConsultScreen] ||
            screenType === ScreenTypes[ScreenTypes.ListScreen] ||
            screenType === ScreenTypes[ScreenTypes.SpecialScreen];
    }

    GetHomeScreenType(homeScreen: string) {
        let splitHomeScreen = homeScreen.split('.');
        let screenType = splitHomeScreen[1];
        switch (screenType) {
            case ScreenTypes[ScreenTypes.ConsultScreen]:
                return ScreenTypes.ConsultScreen;

            case ScreenTypes[ScreenTypes.ListScreen]:
                return ScreenTypes.ListScreen;

            case ScreenTypes[ScreenTypes.SpecialScreen]:
                return ScreenTypes.SpecialScreen;

            default:
                return null;

        }
    }

    GoToHomeScreen(homeScreen: string) {
        let splitHomeScreen = homeScreen.split('.'),
            homeScreenName = splitHomeScreen[0];
        BlockUI.Block();
        TableStore.Get({TableName: homeScreenName})
            .then((table: any) => {
                ScreenManager.GetScreenByScreenType(table.Id, this.GetHomeScreenType(homeScreen), 0)
                    .then(screen => {
                        screen.SetIsReady(true);
                        MenuManager.Instance.GoToScreen(screen);
                        MenuManager.SetNewTitle(table.TranslatedName ? table.TranslatedName : table.Name);
                    })
                    .fail(err => {
                        new Notifier().Failed(NOTIFICATIONS.HOME_SCREEN_SUBJECT_ENTITY.replace('{subjectEntity}', err.message));
                        this.ShowStaticScreen(NavigationTypes.Portlets);
                    })
            })
            .fail((err: any) => {
                new Notifier().Failed(NOTIFICATIONS.CANNOT_OPEN_THE_HOME_SCREEN_SUBJECTENTITY_ENTTY_SCREEN.replace('{subjectEntity}', homeScreenName));
                this.ShowStaticScreen(NavigationTypes.Portlets);
            })
            .always(() => {
                BlockUI.Unblock();
            });
    }

    HandleUrl(){
        let url = new URL(window.location.href);
        let isValid = true;

        if(url.pathname.toLocaleLowerCase() === '/view'){
            let recordId = url.searchParams.get("recordId");
            let entityId = url.searchParams.get("entityId");
            let userId = url.searchParams.get("userId");
            let dbName = url.searchParams.get("db");

            if(userId && UserManager.Instance.CurrentUser.Id !== +userId){
                new Notifier().Failed('Cannot open the record: Invalid user');
                isValid = false;
            };

            if(UserManager.Instance.CurrentUser.DbName !== dbName){
                new Notifier().Failed('Cannot open the record: Invalid database');
                isValid = false;
            };

            if(isValid){
                RecordSecurityStore.IsUserAllowedTo(+entityId, +recordId, RECORD_SECURITY_WORDS.ReadOnly)
                .always(()=>{
                    BlockUI.Unblock();
                }).then(result=>{

                    if (!result.ResultObject) {
                        new Notifier().Failed('Cannot open the record: Access denied');
                        return;
                    }

                    this.GoToRecordScreen(+entityId, +recordId, 0).then((screen: BaseScreen)=>{
                        if (screen.IsConsultScreen) {
                            PathRunner.Instance.GetUnit(+entityId, screen.IsSpecialScreenExist).LoadNewData(+recordId);
                        }
                    });

                });
            }
        }else{
            isValid = false;
        }
        
        this.UpdateUrl();
        return isValid;
    }

    private UpdateUrl(){
        let newUrl = window.location.origin + '?db=' + UserManager.Instance.CurrentUser.DbName;
        if(window.location.href != newUrl){
            window.history.pushState({path: newUrl}, '', newUrl);
        }
    }

    RestoreMenuState() {

        if(this.HandleUrl()){
            return;
        }

        const homeScreen = GlobalManager.Instance.GetGlobal(GLOBALS.HOME_SCREEN);
        if (UserManager.Instance.CurrentUser.UserType === UserTypes.Gdpr) {
            this.GetMyContact();
            return;
        }

        var state = this._history.getState();
        if (state) {
            document.title = state.title; // added Screen Title History
            LockManager.Instance.ReleaseAllLocks();
            var stateData = state.data as IHistoryState;
            this._currentSate = stateData;

            if (!this.StateIsValid(stateData)) {
                if (!!homeScreen && this.IsHomeScreenType(homeScreen)) {
                    this.GoToHomeScreen(homeScreen);
                    return;
                } else {
                    if (!!homeScreen) {
                        let splitHomeScreen = homeScreen.split('.'),
                            screenType = splitHomeScreen[1];
                        if (screenType === ScreenTypes[ScreenTypes.EditScreen]) {
                            new Notifier().Failed(NOTIFICATIONS.EDITSCREEN_CANNOT_BE_USED_AS_A_HOME_SCREEN);
                        } else {
                            new Notifier().Failed(NOTIFICATIONS.CANNOT_OPEN_THE_HOME_SCREEN_SUBJECTENTITY_ENTTY_SCREEN.replace('{subjectEntity}', homeScreen));
                        }
                    }
                    this.ShowStaticScreen(NavigationTypes.Portlets);
                    return;
                }
            }

            if (stateData.NavigationType === NavigationTypes.DesignScreen) {
                if (stateData.ScreenId) {
                    this.BackToScreen(state.data);
                } else {
                    this.ShowStaticScreen(NavigationTypes.Portlets);
                }
            } else {
                this.ShowStaticScreen(stateData.NavigationType);
            }
        } else {
            this.ShowStaticScreen(NavigationTypes.Portlets);
            this.PushState(null, NavigationTypes.Portlets);
        }
    }

	RestoreMenuAreaState() {
		const historyState = this._history.getState();
		const stateData = historyState && historyState.data as IHistoryState;

		if (!stateData || !stateData.EntityId) {
			return;
		}

		var areaScreen = this._menuAreas.GetScreenByEntityId(stateData.EntityId);
		var area = this._menuAreas.GetAreaByEntityId(stateData.EntityId);

		if (areaScreen && area && (areaScreen !== this._selectedScreen || area !== this._selectedArea)) {
			this.SwitchMenu(areaScreen, area);
		}
	}

    MobileVersion() {
        var isMobile = MobileChecker.IsMobile();
        var screenWidth = $(window).width();
        var menuBlock = $('.page-sidebar-menu');
        if (isMobile || screenWidth < 1400) {
            $(menuBlock).addClass('page-sidebar-menu-closed');
            $('body').addClass('page-sidebar-closed device');
        }
        if (isMobile || screenWidth <= 992) {
            $('body').addClass('device');
            $('body').removeClass('page-sidebar-closed');
            $(menuBlock).removeClass('page-sidebar-menu-closed');
        }
    }

    static get Instance(): MenuManager {
        if (MenuManager.instance == null) {
            MenuManager.allowInstantiation = true;
            MenuManager.instance = new MenuManager();
            MenuManager.allowInstantiation = false;
        }

        return MenuManager.instance;
    }

    BackToScreen(state: IHistoryState) {
        this._isDesignersAreaActive(false);
        var areaScreen = this._menuAreas.GetScreenByEntityId(state.EntityId);
        var area = this._menuAreas.GetAreaByEntityId(state.EntityId);

        if (areaScreen) {
            MenuManager.SetNewTitle(areaScreen.Table.TranslatedName ? areaScreen.Table.TranslatedName : areaScreen.Table.Name);
        }

        if (areaScreen && area) {
            if (this._selectedScreen) {
                this._selectedScreen.IsSelected(false);
            }
            this._selectedScreen = areaScreen;
            this._selectedScreen.IsSelected(true);

            if (this._selectedArea) {
                this._selectedArea.IsActive(false);
            }
            this._selectedArea = area;
            this._selectedArea.IsActive(true);

            if (this._activeDesignerMenuItem() === "CyberConfig") {
                this._activeDesignerMenuItem('');
            }
        }

        BlockUI.Block();
        var getScreenPromise = ScreenManager.GetScreenById({ScreenId: state.ScreenId, RecordId: state.RecordId});
        getScreenPromise
            .then(screen => {
                this._currentScreen = screen;
                this.ChangeTableTypeFlowFolder();
                if (ScreenTypes[screen.GetType()] === ScreenTypes.EditScreen) {
                    this.ShowEditScreen(screen);
                } else {
                    PubSub.publish(BREADCRUMBS_NEW_VALUE_TOPIC, {
                        Screen: screen,
                        CleanRecords: true
                    });
                    this.GetFieldRecord(screen.GetRecordId(), screen.GetEntityId());

                    var screenState = _.find(this._screenStates, item => {
                        return item.Guid === screen.GetGuid()
                    });
                    if (screenState) {
                        screen.RestoreState(screenState);
                    }

                    if (ScreenTypes[screen.GetType()] === ScreenTypes.ConsultScreen) {
                        PathRunner.Instance.GetUnit(state.EntityId, screen.IsSpecialScreenExist).LoadNewData(state.RecordId);
                    }
                    this.CashedScreen = screen;
                    screen.RenderByTargetId(this._pageBodyContainerId);
                    screen.SetIsReady(true);
                }
            })
            .always(() => {
                BlockUI.Unblock();
            });
    }

    GetFieldRecord(recordId: number, tableId?: number, tableName?: string): void{
        if (recordId) {
            RecordStore.GetRecord({
                TableName: tableName,
                TableId: tableId,
                RecordId: recordId
            })
                .then((data)=> {
                    let field: FieldDataModel = _.find(data.Fields, (field: FieldDataModel)=> field.FieldName === 'NAME');

                    if (field) {
                        PubSub.publish(BREADCRUMBS_FIELD_RECORD, {
                            Field: field
                        });
                    }
                })
                .fail((err)=>{
                    console.log(err)
                })
        } else {
            PubSub.publish(BREADCRUMBS_FIELD_RECORD, {
                Field: null
            });
        }
    }

    InitKnockout() {
        ko.setTemplateEngine(stringTemplateEngine.StringTemplateEngine);
        KnockoutExtenions.Init();
    }

    SelectedScreen(screen, isOpenInModal?: boolean) {

        this._currentScreen = screen;
        this.ChangeTableTypeFlowFolder();
        var areaScreen = this._menuAreas.GetScreenByEntityId(screen.GetEntityId());
        var area = this._menuAreas.GetAreaByEntityId(screen.GetEntityId());

        if (areaScreen && area) {
            if (!isOpenInModal) {
                MenuManager.SetNewTitle(areaScreen.Table.TranslatedName ? areaScreen.Table.TranslatedName : areaScreen.Table.Name);
                if (this._selectedScreen) {
                    this._selectedScreen.IsSelected(false);
                }
                this._selectedScreen = areaScreen;
                this._selectedScreen.IsSelected(true);

                if (this._selectedArea) {
                    this._selectedArea.IsActive(false);
                }
                this._selectedArea = area;
                this._selectedArea.IsActive(true);
            }
        }
    }

    GoToScreenByType(entityId: number, screenType: ScreenTypes, isOpenInModal: boolean = false) {
        var recordId = 0;
        var recordTypeId = 0;
        var lastRecentRecord = UserVarsManager.Instance.GetLastRecentRecordByEntityId(entityId);

        if (lastRecentRecord && (NotTypedScreenTypes.indexOf(screenType) < 0 || screenType === ScreenTypes.StepsScreen)) {
            recordId = lastRecentRecord.id;
            recordTypeId = lastRecentRecord.typeId;
        }

        BlockUI.Block();
        var getScreenPromise = ScreenManager.GetScreenByScreenType(entityId, screenType, recordId);
        getScreenPromise.then(screen => {
            this.ResetScreenStyle(isOpenInModal);
            if (!isOpenInModal) {
                LockManager.Instance.ReleaseAllLocks();
                this.CashedScreen = this._currentScreen;
                this.PushState(screen, NavigationTypes.DesignScreen);
                screen.RenderByTargetId(this._pageBodyContainerId);

                this.SelectedScreen(screen, isOpenInModal);

            } else {
                screen.ShowInModal();
            }
            screen.SetIsReady(true);
        }).always(() => {
            BlockUI.Unblock();
        }).fail(err => new Notifier().Warning(err.message));
    }

    ScrollToAgendaControl(screen: IScreen, recordId: number, valTableId: number) {
        const tabControl = screen.GetControlByType('Tab') as Tab;
        if (tabControl) {
            let tabWithScheduler: TabPage,
                tabWithSchedulerIndex,
                agendaControlInTab: Agenda,
                isFound;

            _.forEach(tabControl.GetAllSubControls(), (control, index) => {
                if (!isFound && (control.GetType() === CONTROL_TYPES.TabPage)) {
                    agendaControlInTab = _.find(control.GetAllSubControls(), (subControl) => {
                        return subControl.GetType() === CONTROL_TYPES.Agenda;
                    }) as Agenda;
                    if (agendaControlInTab) {
                        isFound = true;
                        tabWithScheduler = control as TabPage;
                        tabWithSchedulerIndex = index;
                    }
                }
            });

            if (agendaControlInTab) {
                BlockUI.Block();

                RecordStore.GetRecord({TableId: valTableId, RecordId: recordId})
                    .always(() => BlockUI.Unblock())
                    .then((record: any) => {
                        const starting = record.Fields.find(f => f.FieldName === 'STARTING');
                        if (starting) {
                            tabControl.SetActiveTab(tabWithSchedulerIndex, {
                                controlId: agendaControlInTab.GetModel().Id,
                                params: {starting: starting.FieldValue},
                                action: 'HyperlinkAgenda'
                            });
                        }

                    })
                    .fail(err => new Notifier().Warning(err.message));
            }
        } else {
            const agendaControl = screen.GetControlByType('Agenda') as Agenda;
            if (agendaControl) {
                BlockUI.Block();

                RecordStore.GetRecord({TableId: valTableId, RecordId: recordId})
                    .always(() => BlockUI.Unblock())
                    .then((record: any) => {
                        const starting = record.Fields.find(f => f.FieldName === 'STARTING');
                        if (starting) {
                            agendaControl.SetStarting(starting.FieldValue);
                        }
                    })
                    .fail(err => new Notifier().Warning(err.message));
            }

        }
    }

    GoToScreen(
        screen: IScreen,
        isOpenInModal: boolean = false,
        currentRow?: GridRow,
        rowList?: Array<GridRow>,
        typeId?: number,
        isOpenAgenda?: false,
        recordId?: number,
        entityId?: number,
        isHyperLink?: boolean
    ) {
        if (screen) {
            if (!isOpenInModal) {
                this.PushState(screen, NavigationTypes.DesignScreen);
                if (ScreenTypes[screen.GetType()] === ScreenTypes.EditScreen) {
                    this.ShowEditScreen(screen);
                } else {
                    screen.RenderByTargetId(this._pageBodyContainerId);
                }
            } else {
                screen.SetCurrentGridRowData(currentRow);
                screen.SetGridRowListData(rowList);
                screen.ShowInModal(isHyperLink ? 'hyperLinkModal' : '');
            }

            if (isOpenAgenda) {
                this.ScrollToAgendaControl(screen, recordId, entityId);
            }

            if (this.CashedScreen && ScreenTypes[screen.GetType()] === ScreenTypes.EditScreen && ScreenTypes[this.CashedScreen.GetType()] === ScreenTypes.ListScreen) {
                screen.On(EDIT_SCREEN_EVENTS.RECORD_SAVED, this, (evtArgs) => {
                    this.CashedScreen.Refresh();
                })
            } else if (!isOpenInModal) {
                this.CashedScreen = screen;
            }
        }
    }

    BackToPreviousScreen(isOpenInModal: boolean = false) {
        if (this.CashedScreen) {
            BlockUI.Block();
            ScreenManager.GetScreenById({
                ScreenId: this.CashedScreen.GetId(),
                RecordId: this.CashedScreen.GetRecordId()
            })
                .always(() => {
                    BlockUI.Unblock();
                })
                .then((screen) => {
                    if (!isOpenInModal) {
                        this.PushState(screen, NavigationTypes.DesignScreen, { previousScreen: true });
                        screen.RenderByTargetId(this._pageBodyContainerId);
                        screen.SetIsReady(true);
                    } else {
                        screen.ShowInModal();
                    }
                    this.CashedScreen = screen;
                });
        }
    }

    OpenBreadcrumb({ record, index }: { record: { Screen?: IScreen }; index: number }) {
        if (record.Screen) {
            BlockUI.Block();
            ScreenManager.GetScreenById({
                ScreenId: record.Screen.GetId(),
                RecordId: record.Screen.GetRecordId()
            })
                .always(() => {
                    BlockUI.Unblock();
                })
                .then((screen) => {
                    this.PushState(screen, NavigationTypes.DesignScreen, { breadcrumbIndex: index });

                    if (ScreenTypes[screen.GetType()] === ScreenTypes.ConsultScreen) {
                        PathRunner.Instance.GetUnit(record.Screen.GetEntityId(), screen.IsSpecialScreenExist).LoadNewData(record.Screen.GetRecordId());
                    }
                    this.CashedScreen = screen;
                    screen.RenderByTargetId(this._pageBodyContainerId);
                    screen.SetIsReady(true);
                });
        } else {
            this.PushState(null, NavigationTypes.Portlets, { breadcrumbIndex: index });
            this.ShowPortlets();
        }
    }

    ResetZIndexValueAndReleaseAllLocks() {
        LockManager.Instance.ReleaseAllLocks();
        ZIndexManager.Instance.ResetValue;
    }

    ProhibitionAutoStopTimerForCurrentScreen(currentScreen: IScreen, newScreen: IScreen): void {
        if (currentScreen && (newScreen.GetRecordId() === currentScreen.GetRecordId()) ) {
            let timerControl: IControl = currentScreen.GetControl('Timer');
            if (timerControl instanceof Timer) {
                if (timerControl.TimerAutostart && timerControl.HasTimerStarted()) {
                    currentScreen.AllowTimerAutoStop = false;
                }
            }
        }
    }

    ShowScreen(screenModel: DesignedScreenModel, menuAreaModel: MenuAreaModel): void {
        if (this._help.IsHelpButtonPressed()) {
            this._help.ShowEntityHelp(screenModel.Table.Id);
            return;
        }

        this.SwitchMenu(screenModel, menuAreaModel);
        BlockUI.Block();
        this.ResetZIndexValueAndReleaseAllLocks();
        UserVarsManager.Instance.RemovePreselectTabData(screenModel.Id);
        var lastRecentRecord = UserVarsManager.Instance.GetLastRecentRecordByEntityId(screenModel.Table.Id);

        var getScreenPromise: P.Promise<IScreen>;

        if (lastRecentRecord && NotTypedScreenTypes.indexOf(ScreenTypes[screenModel.TypeName]) < 0) {
			if (ScreenTypes[screenModel.TypeName] === ScreenTypes.EditScreen) {
				getScreenPromise = this.GetEditScreenWithTypeTransformation(screenModel.Table.Id, lastRecentRecord.id, lastRecentRecord.typeId);
			} else {
				getScreenPromise = ScreenManager.GetScreenByScreenType(screenModel.Table.Id, ScreenTypes[screenModel.TypeName], lastRecentRecord.id);
			}
        } else {
            getScreenPromise = ScreenManager.GetScreenById({ScreenId: screenModel.Id, RecordId: 0});
        }
        getScreenPromise
            .always(() => {
                if (ScreenTypes[screenModel.TypeName] !== ScreenTypes.EditScreen) {
                    BlockUI.Unblock();
                }
            })
            .fail(() => {
                ScreenManager.GetScreenById({ScreenId: screenModel.Id, RecordId: 0}).then((screen) => {
                    const isSpecialScreenExist = screen && screen.IsSpecialScreenExist;
                    if (ScreenTypes[screenModel.TypeName] === ScreenTypes.ConsultScreen) {
                        PathRunner.Instance.GetUnit(screenModel.Table.Id, isSpecialScreenExist).Clear();
                    }

                    screen.RenderByTargetId(this._pageBodyContainerId);
                    this.CashedScreen = screen;
                    this.PushState(screen, NavigationTypes.DesignScreen, { triggeredFromMenu: true });
                    screen.SetIsReady(true);
                });
            })
            .then(screen => {
                if (screen.ScreenDoesNotExistError) {
                    Notifier.Failed(screen.ScreenDoesNotExistErrorMessage);
                    this.GoToConsultScreen(screenModel.Table.Id, 0, 0);
                    return;
                }

                const isSpecialScreenExist = screen && screen.IsSpecialScreenExist;
                const screenRecordId = screen && screen.GetRecordId();

                this.ProhibitionAutoStopTimerForCurrentScreen(this._currentScreen, screen);

                if (ScreenTypes[screenModel.TypeName] === ScreenTypes.ConsultScreen) {
                    if(screenRecordId) {
                        PathRunner.Instance.GetUnit(screenModel.Table.Id, isSpecialScreenExist).LoadNewData(screenRecordId);
                    }
                    else {
                        PathRunner.Instance.GetUnit(screenModel.Table.Id, isSpecialScreenExist).Clear();
                    }
                }

                if (ScreenTypes[screenModel.TypeName] === ScreenTypes.EditScreen) {
                    this.ShowEditScreen(screen);
                } else {
                    screen.RenderByTargetId(this._pageBodyContainerId);
                    this.CashedScreen = screen;
                    this.PushState(screen, NavigationTypes.DesignScreen, { triggeredFromMenu: true });
                    screen.SetIsReady(true);
                    LockManager.Instance.ReleaseAllLocks();
                }
                $("html, body").animate({scrollTop: 0}, 600);

                this._currentScreen = screen;
            });
    }

    ShowEditScreen(screen: IScreen) {
        let editScreen = screen as EditScreen;
        LockManager.Instance.TryLock(editScreen.GetEntityId(), editScreen.GetRecordId())
            .then(() => {

                LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs) => {
                    if (eventArgs.data.TableId === editScreen.GetEntityId() && eventArgs.data.RecordId === editScreen.GetRecordId()) {
                        if (editScreen.IsInModal()) {
                            editScreen.Close();
                        }
                    }
                });

                editScreen.On("RECORD_SAVED", this, (eventArgs) => {
                    var notifier = new Notifier();
                    notifier.Success(NOTIFICATIONS.RECORD_UPDATED);
                    LockManager.Instance.ReleaseLock(editScreen.GetEntityId(), editScreen.GetRecordId());
                });

                editScreen.On("RECORD_REMOVED", this, (eventArgs) => {
                    const notifier = new Notifier();
                    notifier.Success(editScreen.DeleteRecordSuccessNotification);
                    LockManager.Instance.ReleaseLock(editScreen.GetEntityId(), editScreen.GetRecordId());
                });

                editScreen.On("RECORD_SELECTED", this, (eventArgs) => {
	                var getScreenPromise = this.GetEditScreenWithTypeTransformation(editScreen.GetEntityId(),
	                    eventArgs.data.RecordId,
						eventArgs.data.TableTypeId);

                    BlockUI.Block();
                    getScreenPromise
                        .then(screen => {
                            LockManager.Instance.ReleaseLock(editScreen.GetEntityId(), editScreen.GetRecordId());
                            this._currentScreen = screen;
                            this.ChangeTableTypeFlowFolder();
                            this.ShowEditScreen(screen);
                            screen.RenderByTargetId(this._pageBodyContainerId);
                            screen.SetIsReady(true);

                            var baseScreen = screen as BaseScreen;
                            var textSearchTerm = baseScreen.GetControl<Search>('Search');
                            if (textSearchTerm) {
                                textSearchTerm.SetSearchTerm(eventArgs.data.SearchTerm);
                            }
                        }).always(() => {
                        BlockUI.Unblock();
                    });
                });

                editScreen.RenderByTargetId(this._pageBodyContainerId);
                this.CashedScreen = editScreen;
                this.PushState(editScreen, NavigationTypes.DesignScreen);
                editScreen.SetIsReady(true);
            })
            .always(() => BlockUI.Unblock());
    }


    SwitchMenu(screen: DesignedScreenModel, menuAreaModel: MenuAreaModel) {
        this._isDesignersAreaActive(false);
        if (this._selectedScreen) {
            this._selectedScreen.IsSelected(false);
        }
        this._selectedScreen = screen;
        this._selectedScreen.IsSelected(true);

        if (this._selectedArea) {
            this._selectedArea.IsActive(false);
        }
        this._selectedArea = menuAreaModel;
        this._selectedArea.IsActive(true);

        this.ResetScreenStyle();
        MenuManager.SetNewTitle(screen.Table.TranslatedName ? screen.Table.TranslatedName : screen.Table.Name);
    }

    ResetScreenStyle(isOpenInModal: boolean = false): void {
        let elements = [
            $(document.body).find(".page-container"),
            $(document.body).find(".page-header.navbar")
        ];
        if (isOpenInModal) {
            elements = []
        }
        let resetProperties: CustomStyleProperties = {
            'background-color': '',
            '--page-header-hover-color': '',
            '--root-background-color': ''
        };
        elements.forEach(element => {
            this.ResetStyleProperty(element, resetProperties);
        });
    }

    ResetStyleProperty(element: JQuery<HTMLElement> | null, properties: CustomStyleProperties | null): void {
        if ($(element).length) {
            _.each(properties, (value: string | null, property: string):void => {
                $(element).get(0).style.setProperty(property, value);
            })
        }
    }

    ShowSearchScreen(screen: DesignedScreenModel, menuAreaModel: MenuAreaModel) {

        LockManager.Instance.ReleaseAllLocks();

        var searchScreen = new SearchScreen({
            EntityId: screen.Table.Id,
            SearchTerm: '',
            RecentAndFavoritesActive: true
        });

        searchScreen.On("RECORD_SELECTED", this, (eventArgs) => {
            this.SwitchMenu(screen, menuAreaModel);
            var recordId = eventArgs.data.RecordId;
            var typeId = eventArgs.data.TypeId;

            UserVarsManager.Instance.AddRecent(screen.Table.Id, recordId, typeId);

            if (ScreenTypes[screen.TypeName] === ScreenTypes.ListScreen) {
                this.GoToRecordScreen(screen.Table.Id, recordId, typeId, true);
            } else {
                BlockUI.Block();
                ScreenManager
                    .GetScreenByScreenType(screen.Table.Id, ScreenTypes[screen.TypeName], recordId)
                    .always(() => {
                        BlockUI.Unblock();
                    })
                    .then(screen => {
                        screen.SetIsReady(true);
                        this.GoToScreen(screen);
                    })
                    .fail(err => new Notifier().Warning(err.message));
            }
        });

        searchScreen.On("ALT_ENTITY_RECORD_SELECTED", this, (eventArgs) => {
            const data = eventArgs.data;
            UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);


            data.IsOpenInModal = false;
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });


        searchScreen.On("NEW_RECORD", this, (eventArgs) => {
            let entityId = screen.Table.Id;
            let typeId = screen.TableTypeId;

            const typeScreen = new TypeScreen(entityId, typeId, true);

            typeScreen.On("TYPES_NOT_FOUND", this, (eventArgs) => {
                new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
            });

            typeScreen.On("TYPES_FOUND", this, (eventArgs) => {
                searchScreen.Cancel();
            });

            typeScreen.On("TYPE_SELECTED", this, (eventArgs) => {
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;
                const exampleRecordId = eventArgs.data.ExampleRecordId;

                this.NewRecord(typeId, kindId, exampleRecordId, entityId);
            });

            typeScreen.Show();
        });

        searchScreen.Show();
    }

    NewRecord(tableTypeId: number, kindId: number, exampleRecordId: number, entityId: number) {
        ScreenManager.GetEditScreen({
            EntityId: entityId,
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0
        }).then((screen: EditScreen) => {
            const editScreen = screen;
            editScreen.IsDataFromExample = exampleRecordId > 0;
            editScreen.UseLinking = true;

            screen.On("RECORD_SAVED", this, (eventArgs) => {
                const notifier = new Notifier();
                notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                this.LoadScreenFor(tableTypeId, eventArgs.data.RecordId, entityId);
                UserVarsManager.Instance.AddRecent(entityId, eventArgs.data.RecordId, tableTypeId);
            });

            screen.ShowInModal();
        }).fail(error => {
            new Notifier().Warning(error.message);
        });
    }

    LoadScreenFor(tableTypeId: number, recordId: number, entityId: number) {
        BlockUI.Block();
        ScreenManager.GetScreenByScreenType(entityId, ScreenTypes.ConsultScreen, recordId)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(screen => {
                screen.SetIsReady(true);
                MenuManager.Instance.GoToScreen(screen);
            }).fail(err => new Notifier().Warning(err.message));
    }


    Bind(target: string): void {
        var targetElement = document.getElementById(target);
        if (targetElement != null) {
            ko.applyBindings(this, targetElement);
        }
    }

    NavigateToHomePage() {
        if (!this.IsAuthenticated()) {
            return;
        }
        this._isDesignersAreaActive(false);
        if (this._selectedScreen) {
            this._selectedScreen.IsSelected(false);
        }

        if (UserManager.Instance.CurrentUser.UserType === UserTypes.Gdpr) {
            this.GetMyContact();
            return;
        }

        MenuManager.SetNewTitle(LABELS.MY_DAILY_STUFF);

        LockManager.Instance.ReleaseAllLocks();
        this.PushState(null, NavigationTypes.Portlets);
        this.ShowPortlets();
    }

    ShowPortlets() {

        this._isDesignersAreaActive(false);
        this._isDesignersAreaActive.valueHasMutated();
        this._activeDesignerMenuItem('');

        const portletsPage = new PortletsPage();
        portletsPage.Render(this._pageBodyContainerId);

        this._menuAreas.CollapseAll();

        this.CashedScreen = null;
        this.ResetScreenStyle();

        MenuManager.SetNewTitle(LABELS.MY_DAILY_STUFF);
    }

    ShowStaticScreen(navigationType: NavigationTypes) {
        let arePortletsShown = false;

        switch (navigationType) {
            case NavigationTypes.Portlets:
                this.ShowPortlets();
                arePortletsShown = true;
                break;
            case NavigationTypes.LookupEditor:
                this.ShowLookupEditor(false);
                this._isDesignersAreaActive(true);
                break;
            case NavigationTypes.DatabaseDesigner:
                this.ShowDatabaseDesigner(false);
                this._isDesignersAreaActive(true);
                break;
            case NavigationTypes.FormDesigner:
                this.ShowFormDesigner(false);
                this._isDesignersAreaActive(true);
                break;

            case NavigationTypes.NetworkDesigner:
                this.ShowNetworkDesigner(false);
                this._isDesignersAreaActive(true);
                break;
            case NavigationTypes.CanvasDesigner:
                this.ShowCanvasDesigner(false);
                this._isDesignersAreaActive(true);
                break;
            case NavigationTypes.FunctionDesigner:
                this.ShowFunctionDesigner(false);
                this._isDesignersAreaActive(true);
				break;
            case NavigationTypes.FieldCollectionDesigner:
				this.ShowFieldCollection(false);
	            this._isDesignersAreaActive(true);
	            break;
            case NavigationTypes.CdToolkit:
                this.ShowCdToolkit(false);
                this._isDesignersAreaActive(true);
                break;
            case NavigationTypes.TranslationEditor:
                this.ShowTranslationEditor(false);
                this._isDesignersAreaActive(true);
                break;
            case NavigationTypes.CyberConfig:
                this.ShowCyberConfigManager(false);
                break;
            case NavigationTypes.BIDesigner:
                this.ShowBIDesigner(false);
                this._isDesignersAreaActive(true);
                break;
            case NavigationTypes.Administration:
                if(this._enableAdministrationMenu()){
                    this.ShowAdministration(false);
                    this._isDesignersAreaActive(false);
                    break;
                }
    
            default:
                this.ShowPortlets();
                this._isDesignersAreaActive(false);
                arePortletsShown = true;
        }

        PubSub.publish(BREADCRUMBS_NEW_VALUE_TOPIC, {
            CleanRecords: true,
            DailyStuff: arePortletsShown
        });
        this.GetFieldRecord(null);

        if (this._selectedScreen) {
            this._selectedScreen.IsSelected(false);
        }

        if (this._selectedArea) {
            this._selectedArea.IsActive(false);
        }
    }

    GetMyContact() {
        new UserMenu().GetMyContact();
    }

    ShowLookupEditor(saveState: boolean = true): void {

        this.ResetZIndexValueAndReleaseAllLocks();
        this._activeDesignerMenuItem('LookupEditor');
        var lookupEditor = new LookupEditor();
        lookupEditor.Render(this._pageBodyContainerId);
        if (saveState) {
            this.PushState(null, NavigationTypes.LookupEditor);
        }

        this.CashedScreen = null;
        this.ResetScreenStyle();

        MenuManager.SetNewTitle(LABELS.LOOKUP_EDITOR);
    }

    ShowBIDesigner(saveState: boolean = true): void {

        this.ResetZIndexValueAndReleaseAllLocks();
        this._activeDesignerMenuItem('BIDesigner');
        var designer = new BoldBiDesignerPage();
        designer.Render(this._pageBodyContainerId);
        if (saveState) {
            this.PushState(null, NavigationTypes.BIDesigner);
        }

        this.CashedScreen = null;
        this.ResetScreenStyle();

        MenuManager.SetNewTitle('SBI designer');
    }


    ShowFormDesigner(saveState: boolean = true): void {

        this.ResetZIndexValueAndReleaseAllLocks();
        this._activeDesignerMenuItem('FormDesigner');
        var formDesignerPage = new FormDesignerPage();
        formDesignerPage.Render(this._pageBodyContainerId);
        if (saveState) {
            this.PushState(null, NavigationTypes.FormDesigner);
        }

        this.CashedScreen = null;
        this.ResetScreenStyle();

        MenuManager.SetNewTitle(LABELS.FORM_DESIGNER);
    }

    ShowTranslationEditor(saveState: boolean = true): void {

        this.ResetZIndexValueAndReleaseAllLocks();
        this._activeDesignerMenuItem('TranslationEditor');
        var translationEditor = new TranslationEditorPage();
        translationEditor.Render(this._pageBodyContainerId);
        if (saveState) {
            this.PushState(null, NavigationTypes.TranslationEditor);
        }

        this.CashedScreen = null;
        this.ResetScreenStyle();

        MenuManager.SetNewTitle(LABELS.TRANSLATION_EDITOR);
    }

    ShowCanvasDesigner(saveState: boolean = true): void {

        this.ResetZIndexValueAndReleaseAllLocks();
        this._activeDesignerMenuItem('CanvasDesigner');
        var canvasDesignerPage = new CanvasDesignerPage();
        canvasDesignerPage.Render(this._pageBodyContainerId);
        if (saveState) {
            this.PushState(null, NavigationTypes.CanvasDesigner);
        }

        this.CashedScreen = null;
        this.ResetScreenStyle();

        MenuManager.SetNewTitle(LABELS.CANVAS_DESIGNER);
    }

    ShowFunctionDesigner(saveState: boolean = true): void {
        this.ResetZIndexValueAndReleaseAllLocks();
        this._activeDesignerMenuItem('FunctionDesigner');
        var functionDesignerPage = new FunctionDesignerPage();
        functionDesignerPage.Render(this._pageBodyContainerId);
        if (saveState) {
            this.PushState(null, NavigationTypes.FunctionDesigner);
        }

        this.CashedScreen = null;
        this.ResetScreenStyle();

        MenuManager.SetNewTitle(LABELS.FUNCTION_DESIGNER);
    }

    ShowAdministration(saveState: boolean = true): void {
        let pageBodyContainerId = `#${this._pageBodyContainerId}`;
        ko.cleanNode($(pageBodyContainerId)[0]);

        this.ResetZIndexValueAndReleaseAllLocks();
        $(pageBodyContainerId).contextmenu('destroy');

        let administrationPage = new AdministrationPage();
        administrationPage.Render(this._pageBodyContainerId);

        if (saveState) {
            this.PushState(null, NavigationTypes.Administration);
        }

        this._activeDesignerMenuItem('Administration');
        MenuManager.SetNewTitle('Administration');
    }

    ShowDatabaseDesigner(saveState: boolean = true): void {
        let pageBodyContainerId = `#${this._pageBodyContainerId}`;
        ko.cleanNode($(pageBodyContainerId)[0]);

        this.ResetZIndexValueAndReleaseAllLocks();
        $(pageBodyContainerId).contextmenu('destroy');

        import('Pages/DatabaseDesignerPage').then(({DatabaseDesignerPage}) => {
            this._activeDesignerMenuItem('DatabaseDesginer');

            var databaseDesignerPage = new DatabaseDesignerPage();
            databaseDesignerPage.Render($(pageBodyContainerId));

            if (saveState) {
                this.PushState(null, NavigationTypes.DatabaseDesigner);
            }

            this.CashedScreen = null;
            this.ResetScreenStyle();

            MenuManager.SetNewTitle(LABELS.DATABASE_DESIGNER);
        });
    }

    ShowDictionaryComparison(){
        this._activeDesignerMenuItem('DictionaryComparison');
        let page = new DictionaryComparisonPage();
        this.ResetScreenStyle();
        page.Render(this._pageBodyContainerId);
    }

    ShowNetworkDesigner(saveState: boolean = true) : void {

        this.ResetZIndexValueAndReleaseAllLocks();

        this._activeDesignerMenuItem('NetworkDesigner');

        const networkDesigner = new NetworkDesigner();
        networkDesigner.Render(this._pageBodyContainerId);

        if (saveState) {
            this.PushState(null, NavigationTypes.NetworkDesigner);
        }

        this.CashedScreen = null;
        this.ResetScreenStyle();

        MenuManager.SetNewTitle(LABELS.NETWORK_DESIGNER);
    }

	ShowFieldCollection(saveState: boolean = true): void {

        this.ResetZIndexValueAndReleaseAllLocks();

        this._activeDesignerMenuItem('FieldCollection');

        const fieldCollection = new FieldCollection();

		fieldCollection.Render(this._pageBodyContainerId);

		if (saveState) {
			this.PushState(null, NavigationTypes.FieldCollectionDesigner);
		}

		this.CashedScreen = null;
        this.ResetScreenStyle();

		MenuManager.SetNewTitle(LABELS.FIELD_COLLECTION_DESIGNER);
    }

    ShowCdToolkit(saveState: boolean = true) {

        this.ResetZIndexValueAndReleaseAllLocks();
        $(`#${this._pageBodyContainerId}`).html("");
        this._activeDesignerMenuItem('CdToolkit');
        if (saveState) {
            this.PushState(null, NavigationTypes.CdToolkit);
        }

        this.CashedScreen = null;
        this.ResetScreenStyle();
    }

    ShowCyberConfigManager(saveState: boolean = true): void {

        this.ResetZIndexValueAndReleaseAllLocks();
        this._activeDesignerMenuItem('CyberConfig');
        let manager = new CyberConfigManager();
        manager.Render(this._pageBodyContainerId);

        if (saveState) {
            this.PushState(null, NavigationTypes.CyberConfig);
        }

        this.CashedScreen = null;
        this._isDesignersAreaActive(false);
        this.ResetScreenStyle();

        MenuManager.SetNewTitle(LABELS.ENVIRONMENT_MANAGER);
    }

    GoToConsultScreen(entityId: number, recordId: number, typeId: number, isOpenInModal: boolean = false): P.Promise<IScreen> {
        BlockUI.Block();
        var deferredResult = P.defer<IScreen>();
        ScreenManager.GetScreenByScreenType(entityId, ScreenTypes.ConsultScreen, recordId)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(screen => {
                deferredResult.resolve(screen);
                screen.SetIsReady(true);
                this.GoToScreen(screen, isOpenInModal);

                this.SelectedScreen(screen, isOpenInModal);

            }).fail(err => new Notifier().Warning(err.message));
        return deferredResult.promise();
    }


    GoToRecordScreen(
        entityId: number,
        recordId: number,
        typeId: number,
        isOpenInModal: boolean = false,
        owner: IScreen = null,
        currentRow?,
        rowsList?,
        isOpenAgenda?: false,
        isHyperLink?: boolean
    ) {
        const deferred = P.defer<IScreen>();

        BlockUI.Block();
        const screenType = isOpenAgenda ? ScreenTypes.SpecialScreen : ScreenTypes.ConsultScreen;
        ScreenManager.GetScreenByScreenType(entityId, screenType, recordId)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(screen => {
                if (screen.ScreenDoesNotExistError) {
                    this.HandleGoToRecordScreenError(entityId, recordId, typeId, null, deferred, screen.ScreenDoesNotExistError, isOpenInModal, owner, currentRow, rowsList);
                    return;
                }

                screen.SetSource(owner);
                screen.SetIsReady(true);

                if (owner) {
                    screen.On("RECORD_SAVED", this, (eventArgs) => {
                        owner.Refresh();
                    });

                    screen.On('FOLLOWUP_RECORD', this, (eventArgs) => {
                        if (owner.GetType() === ScreenTypes[ScreenTypes.ConsultScreen]) {
                            (owner as ConsultScreen).OnFollowUpRecordFromEditScreen(eventArgs);
                        }
                    });
                }
                this.GoToScreen(screen, isOpenInModal, currentRow, rowsList, typeId, isOpenAgenda, recordId, entityId, isHyperLink);

                this.SelectedScreen(screen, isOpenInModal);

                deferred.resolve(screen);
            }).fail(err => {
                this.HandleGoToRecordScreenError(entityId, recordId, typeId, err.message, deferred, false, isOpenInModal, owner, currentRow, rowsList);
        });

        return deferred.promise();
    }

    private GoToEditScreen(entityId: number, recordId: number, typeId: number, isOpenInModal: boolean = false, owner: IScreen = null, currentRow?: GridRow, rowsList?: Array<GridRow>) {
        const deferred = P.defer<IScreen>();

        BlockUI.Block();
        LockManager.Instance.TryLock(entityId, recordId).then(() => {
			ScreenManager.GetEditScreen({
				EntityId: entityId,
				TableTypeId: typeId,
				RecordId: recordId
			})
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(screen => {
                    if (owner) {
                        screen.On("RECORD_SAVED", this, (eventArgs) => {
                            owner.Refresh();
                        });
                    }

                    LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs: any) => {
                        if (eventArgs.data.TableId === entityId && eventArgs.data.RecordId === recordId) {
                            screen.Close();
                        }
                    });

                    this.GoToScreen(screen, true, currentRow, rowsList, typeId);

                    screen.On('MODAL_CLOSE',
                        this,
                        () => {
                            LockManager.Instance.ReleaseLock(entityId, recordId);
                        });

                    screen.On("COPY",
                        this,
                        (eventArgs) => {
                            let editScreen = screen as EditScreen;
                            if (editScreen.IsInModal()) {
                                const copyTableType = eventArgs.data.copyToType || typeId;
                                editScreen.NewRecord(copyTableType, null, eventArgs.data.recordId, null, eventArgs.data.dataMode, eventArgs.data.linkToSource, eventArgs.data.aliasSuffix);
                            }
                        });

                    screen.On("FOLLOWUP_RECORD",
                        this,
                        (eventArgs) => {
                            let editScreen = screen as EditScreen;
                            editScreen.Close();

                            let followUpRecord = eventArgs.data.followUpRecordModel;
                            if (screen.IsInModal()) {
                                if (!followUpRecord ||
                                    (followUpRecord.LifeStatusSort !== null
                                        && followUpRecord.LifeStatusSort !== LIFE_STATUS_GROUPS.RETIRED
                                        && !followUpRecord.LifeStatusNoActionNode
                                        && (followUpRecord.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_CURRENT_AND_NEW
                                        || followUpRecord.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_NEW))) {
                                    BlockUI.Block();
                                    ScreenManager.GetEditScreen({
                                        EntityId: entityId,
                                        TableTypeId: typeId,
                                        RecordId: recordId,
                                        LoadAsExample: true,
                                        DataMode: DataModes.FollowUp,
                                        ParentRecordId: recordId,
                                        SubjectLifestatusId: followUpRecord ? followUpRecord.LifeStatusId : null,
                                    }).always(() => {
                                        BlockUI.Unblock();
                                    })
                                        .then(followUpScreen => {
                                            let editfollowUpScreen = followUpScreen as EditScreen;
                                            editfollowUpScreen.IsDataFromExample = true;
                                            editfollowUpScreen.UseLinking = true;
                                            editfollowUpScreen.ParentRecordId = recordId;
                                            editfollowUpScreen.ParentRecordTypeId = typeId;

                                            this.GoToScreen(editfollowUpScreen, true, currentRow, rowsList, typeId);

                                            editfollowUpScreen.On('MODAL_CLOSE',
                                                this,
                                                () => {
                                                    if (owner) {
                                                        owner.Refresh();
                                                    }
                                                });
                                        });
                                }
                            }
                        });

                    deferred.resolve(screen);
                }).fail((err) => {
                new Notifier().Warning(err.message);
                deferred.reject(err);
            });
        });

        return deferred.promise();
    }

	private GetEditScreenWithTypeTransformation(entityId: number, recordId: number, typeId: number) {
		const deferred = P.defer<IScreen>();

		LockManager.Instance.TryLock(entityId, recordId).then(() => {
			ScreenManager.IsTypeTransformationRequired(entityId, recordId)
				.then((result: any) => {
					if (result) {
						const typeScreen = new TypeScreen(entityId, 0, true, false);
						typeScreen.On("TYPE_SELECTED",
							this,
							(eventArgs) => {
								const newTypeId = eventArgs.data.TypeId;
								ScreenManager.GetEditScreen({
									EntityId: entityId,
									TableTypeId: newTypeId,
									RecordId: recordId
								}).then(screen => deferred.resolve(screen)).fail(err => {
								    new Notifier().Warning(err.message);
                                    LockManager.Instance.ReleaseLock(typeId, recordId);
                                    this.RestoreMenuAreaState();
                                    BlockUI.Unblock();
								});
							});
						typeScreen.On("CANCELLED", this, () => {
							this.RestoreMenuAreaState();
						});
						typeScreen.Show();
					} else {
						ScreenManager.GetEditScreen({
							EntityId: entityId,
							TableTypeId: typeId,
							RecordId: recordId
						}).then(screen => deferred.resolve(screen)).fail(err => {
                            new Notifier().Warning(err.message);
                            LockManager.Instance.ReleaseLock(typeId, recordId);
                            this.RestoreMenuAreaState();
                            BlockUI.Unblock();
                        });
					}
				}).fail(err => new Notifier().Warning(err.message));
		});

		return deferred.promise();
	}

	private HandleGoToRecordScreenError(
        entityId: number,
        recordId: number,
        typeId: number,
        errorMessage: string,
        deferred,
        screenDoesNotExistError: boolean = false,
        isOpenInModal: boolean = false,
        owner: IScreen = null,
        currentRow?,
        rowsList?
    ){
        if (typeId !== 0) {
            if (currentRow || screenDoesNotExistError) {
                RecordSecurityStore.IsUserAllowedToEdit(entityId, recordId)
                    .then(result => {
                        if (!result.IsSuccessfull) {
                            return Notifier.Failed(result.ErrorMessage);
                        }

                        if (!result.ResultObject) {
                            return Notifier.Warning(NOTIFICATIONS.YOU_ARE_NOT_ALLOWED_TO_PERFORM_THIS_ACTION);
                        }

                        this.GoToEditScreen(entityId, recordId, typeId, isOpenInModal, owner)
                            .then(screen => deferred.resolve(screen));
                    })
                    .fail(() => Notifier.Failed('Error checking edit rights'));

            } else {
                Notifier.Failed(errorMessage);
            }
        } else {
            ScreenManager.IsTypeTransformationRequired(entityId, recordId)
                .then((result: any) => {
                    if (result) {
                        const typeScreen = new TypeScreen(entityId, 0, true, false);
                        typeScreen.On("TYPE_SELECTED", this, (eventArgs) => {
                            typeId = eventArgs.data.TypeId;
                            this.GoToEditScreen(entityId, recordId, typeId, isOpenInModal, owner)
                                .then(screen => deferred.resolve(screen));
                        });
                        typeScreen.Show();

                    } else if (screenDoesNotExistError) {
                        this.GoToEditScreen(entityId, recordId, typeId, isOpenInModal, owner)
                            .then(screen => deferred.resolve(screen));
                    } else {
                        Notifier.Failed(errorMessage);
                    }

                }).fail(err => Notifier.Warning(err.message));
        }
    }

    private HandleScreenClosedEvent(screen: BaseScreen) {
        const openedScreenIndex = this._openedScreens.indexOf(screen);

        if (openedScreenIndex > -1) {
            this._openedScreens.splice(openedScreenIndex, 1);
            this._currentScreen = null;

            if (screen.IsInModal() && screen.IsPathRunnerRefreshed) {
                this._pathRunnerRefreshed(true);
                screen.IsPathRunnerRefreshed = false;
            }
        }
    }

    RefreshMenuAreas(reloadScreen: boolean = true) {
        this._menuAreas.Refresh(reloadScreen);
    }

    RefreshStaticMenu() {
        const userManager = UserManager.Instance;
        const roles = userManager.GetDataRoles();
        
        this._enableDatabaseDesignerMenu(userManager.IsUserInRole(UserRoles.DesignerLevel1) || userManager.IsUserInRole(UserRoles.DesignerLevel2)
            || userManager.IsUserInRole(UserRoles.DesignerLevel3) || userManager.IsUserInRole(UserRoles.US));
        this._enableFormDesignerMenu(userManager.IsUserInRole(UserRoles.DesignerLevel1) || userManager.IsUserInRole(UserRoles.DesignerLevel2)
            || userManager.IsUserInRole(UserRoles.DesignerLevel3) || userManager.IsUserInRole(UserRoles.US));
        this._enableNetworkDesignerMenu(userManager.IsUserInRole(UserRoles.NetworkManager) || userManager.IsUserInRole(UserRoles.US));

        const hasRoles = _.without(roles, UserDataRoles.Dep).length > 0;
        this._enableFieldCollectionDesignerMenu((hasRoles && userManager.IsUserInRole(UserRoles.DataRole)) || userManager.IsUserInRole(UserRoles.US));

        this._enableCanvasDesignerMenu(userManager.IsUserInRole(UserRoles.DesignerLevel2)
            || userManager.IsUserInRole(UserRoles.DesignerLevel3) || userManager.IsUserInRole(UserRoles.US));
        this._enableFunctionDesignerMenu(userManager.IsUserInRole(UserRoles.DesignerLevel3) || userManager.IsUserInRole(UserRoles.US));

        const SBIDesignerString = CookieManager.GetSBIDesigner();
        const SBIDesigner = SBIDesignerString ? SBIDesignerString === 'true' || SBIDesignerString === 'True' : false;
        this._enableBIDesignerMenu((SBIDesigner && (userManager.IsUserInRole(UserRoles.DesignerLevel2)
            || userManager.IsUserInRole(UserRoles.DesignerLevel3))) || userManager.IsUserInRole(UserRoles.DataRole) || userManager.IsUserInRole(UserRoles.US));

        this._enableLookupEditorMenu(userManager.IsUserInRole(UserRoles.SuperUser) || userManager.IsUserInRole(UserRoles.DesignerLevel1) ||
            userManager.IsUserInRole(UserRoles.DesignerLevel2) || userManager.IsUserInRole(UserRoles.DesignerLevel3) || userManager.IsUserInRole(UserRoles.US));
        this._enableCdToolkitMenu(userManager.IsUserInRole(UserRoles.DesignerLevel1));

        this._enableTranslationEditorMenu(userManager.IsUserInRole(UserRoles.SuperUser) || userManager.IsUserInRole(UserRoles.DesignerLevel1) ||
            userManager.IsUserInRole(UserRoles.DesignerLevel2) || userManager.IsUserInRole(UserRoles.DesignerLevel3) || userManager.IsUserInRole(UserRoles.US));
        this._enableAdministrationMenu(userManager.IsUserInRole(UserRoles.NetworkManager) && UserManager.Instance.CurrentUser.IsLicenseServer);

        this._enableDesignerMenu(
            this._enableDatabaseDesignerMenu() ||
            this._enableFormDesignerMenu() ||
            this._enableNetworkDesignerMenu() ||
            this._enableFieldCollectionDesignerMenu() ||
            this._enableCanvasDesignerMenu() ||
            this._enableFunctionDesignerMenu() ||
            this._enableBIDesignerMenu() ||
            this._enableLookupEditorMenu() ||
            this._enableTranslationEditorMenu()
        );

        this._enableCyberConfigMenu(userManager.IsUserInRole(UserRoles.US));
        this._enableDictionaryComparisonMenu(userManager.IsUserInRole(UserRoles.US));
    }

    InitMobileLayout(): void {
    }

    HidejsPlumbConnections() {
        if (jsPlumb) {
            _.each(jsPlumb.getConnections(), (connection) => {
                jsPlumb.deleteConnection(connection);
            });
        }
    }

    RemoveOverlay() {
        var $preloader = $('.preloader');
        $preloader.detach();
        this.InitProductFruits();
    }

    InitProductFruits(){
        if(this._isProductfruitsInitalized || !UserManager.Instance.CurrentUser){
            return;
        }
        
        productFruits.init('5eZDNfBEDbQCRnbd', 'en', { username: UserManager.Instance.CurrentUser.UserName });
        this._isProductfruitsInitalized = true;
    }

    HideGuestHomePage() {
        $('.page-content').removeClass('guest');
        $('.page-footer').removeClass('guest');

        document.getElementById(this._pageBodyContainerId).classList.remove('guest');
        this._guestHomePage.Detach();
    }

    ShowGuestHomePage() {
        $('.page-content').addClass('guest');
        $('.page-footer').addClass('guest');
        document.getElementById(this._pageBodyContainerId).classList.add('guest');

        this._guestHomePage = new GuestHomePage();
        this._guestHomePage.RenderByTargetId(this._pageBodyContainerId);
    }

    GetIconTemplateName(): string {
        return 'MenuManager/MenuAreas/Templates/IconTemplate';
    }

    PushState(
        screen: IScreen,
        navigationType: NavigationTypes,
        additionalOptions: { previousScreen?: boolean; triggeredFromMenu?: boolean; breadcrumbIndex?: number } = {}
    ) {
        var guid = Guid.NewGuid();
        var user = UserManager.Instance.CurrentUser;
        var newState: IHistoryState = {
            Guid: guid,
            UserId: user.Id,
            SecurityRolesKey: user.SecurityRolesKey,
            DbId: user.DbId,
            ScreenId: screen ? screen.GetId() : null,
            EntityId: screen ? screen.GetEntityId() : null,
            RecordId: screen ? screen.GetRecordId() : null,
            NavigationType: navigationType
        }

        if (navigationType === NavigationTypes.DesignScreen) {
            if (this._currentSate) {
                if (this._currentSate.RecordId === newState.RecordId && this._currentSate.ScreenId === newState.ScreenId) {
                    return;
                }
            }
        } else {
            if (this._currentSate) {
                if (this._currentSate.NavigationType === newState.NavigationType) {
                    return;
                }
            }
        }

        if (screen) {
            PubSub.publish(BREADCRUMBS_NEW_VALUE_TOPIC, {
                Screen: screen,
                LookForPreviousScreen: additionalOptions.previousScreen,
                CleanRecords: additionalOptions.triggeredFromMenu,
                BreadcrumbIndex: additionalOptions.breadcrumbIndex
            });
            this.GetFieldRecord(screen.GetRecordId(), screen.GetEntityId());
        } else if (navigationType) {
            PubSub.publish(BREADCRUMBS_NEW_VALUE_TOPIC, {
                CleanRecords: true,
                BreadcrumbIndex: additionalOptions.breadcrumbIndex,
                DailyStuff: navigationType === NavigationTypes.Portlets
            });
            this.GetFieldRecord(null);
        }

        var screenState = screen ? screen.GetState() : {Guid: null, ActiveControlId: null};
        screenState.Guid = guid;

        if (this._currentSate && this._currentScreen) {
            var screenState = this._currentScreen.GetState();
            if (screenState.ActiveControlId) {
                var state = _.find(this._screenStates, item => {
                    return item.Guid === this._currentSate.Guid
                });
                var stateIndex = this._screenStates.indexOf(state);
                if (state) {
                    state = this._currentScreen.GetState();
                    state.Guid = this._currentSate.Guid;
                    this._screenStates.splice(stateIndex, 1, state);
                } else {
                    var state = this._currentScreen.GetState();
                    state.Guid = this._currentSate.Guid;
                    this._screenStates.push(state);
                }
            }
        }

        this._popState = true;
        this._history.pushState(newState, $(document).find("title").text(), null);
        this._currentScreen = screen;
        this.ChangeTableTypeFlowFolder();
        this._currentSate = newState;
    }

    StateIsValid(state: IHistoryState) {
        const user = UserManager.Instance.CurrentUser;
        return user.Id === state.UserId && state.SecurityRolesKey === user.SecurityRolesKey && state.DbId === user.DbId;
    }
}