import * as _ from 'underscore';

import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';

import {IPropertyDescription, IPropertyOption} from 'Core/GeneralProperties/Managers/BaseProperty';
import {BaseSelectProperty} from 'Core/GeneralProperties/Managers/BaseSelectProperty/BaseSelectProperty';
import {EntityTypesStore} from 'Core/Screens/TypeScreen/Stores/EntityTypesStore';

import {TableTypeModel} from 'Core/Screens/TypeScreen/Models/TableTypeModel';
import {IControl} from 'Core/Controls/IControl';

import SelectEntityTypePropertyTemplate
    from 'Core/GeneralProperties/Managers/SelectEntityTypeProperty/Templates/SelectEntityTypeProperty.html';

ko.templates['Core/GeneralProperties/Managers/SelectEntityTypeProperty/Templates/SelectEntityTypeProperty'] = SelectEntityTypePropertyTemplate;

export class SelectEntityTypeProperty extends BaseSelectProperty {
    private _control: IControl;
    private _propertyValue: IPropertyOption;
    private _isValid: KnockoutObservable<boolean>;
    private _el: HTMLElement;

    constructor(property: IPropertyDescription, propertyValue: IPropertyOption, control: IControl) {
        super(property, propertyValue);

        this._control = control;
        this._propertyValue = propertyValue;
        this._isValid = ko.observable(true);
    }

    IsValid(): boolean {
        return this._isValid();
    }

    ErrorMessage(): string {
        return 'Wait for the control\'s data load';
    }

    GetTemplateName(): string {
        return 'Core/GeneralProperties/Managers/SelectEntityTypeProperty/Templates/SelectEntityTypeProperty';
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
        this._el = el[0];

        this._isValid(false);

        BlockUI.Block({Target: this._el});
        this.LoadData()
            .then(data => {
                this.PopulateData(data.TableTypes);
            })
            .fail(error => {
                Notifier.Failed(error.message);
            })
            .always(() => {
                this._isValid(true);
                BlockUI.Unblock(this._el);
            });
    }

    private LoadData()  {
        return EntityTypesStore.GetAllTypes({
            EntityId: this._control.GetForm().GetScreen().GetEntityId(),
            ParentTypeId: 0,
            WithRoot: false,
            OnlyEnabled: true
        });
    }

    private PopulateData(tableTypes: TableTypeModel[]){
        const nonDashTypes = _.filter(tableTypes, tableType => tableType.Id !== 0);

        let items: IPropertyOption[] = _.map(nonDashTypes,
            (item: TableTypeModel) => {
                return {Name: item.GetName(), Value: item.Id}
            });

        this.Init(items, this._propertyValue);
    }
}